export function frontEndRedirect(url: string) {
  if (typeof window !== "undefined") {
    try {
      return (window.top || window).open(url, "_self");
    } catch {
      return window.open(url, "_self");
    }
  }
}

export function frontEndTabOpen(url: string) {
  if (typeof window !== "undefined") {
    try {
      return (window.top || window).open(url, "_blank");
    } catch {
      return window.open(url, "_blank");
    }
  }
}
